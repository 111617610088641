import * as React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import IndexPage from './IndexPage';
import Layout from '../components/Layout';
import Section from '../components/Section';
import NewsletterSignupForm from '../components/NewsletterSignupForm';
import BlogRoll from '../components/BlogRoll';
import LocalizedLink from '../components/LocalizedLink';

const IndexTemplate = ({ data, pageContext }) => {
  const image = data.indexPage.frontmatter[defaultLocale].image;
  const hero =
    data.indexPage.frontmatter[pageContext.locale].hero ||
    data.indexPage.frontmatter[defaultLocale].hero;
  const researchPublications = data.researchPublications.edges;
  const countryStudies = data.countryStudies.edges;
  const blogPosts = data.blogPosts.edges;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <IndexPage heroImage={image} heroContent={hero} />
        {!!researchPublications?.length && (
          <Section overlaySide="left" colorScheme="grey">
            <div className="container py-12">
              <div className="row flex flex-col md:flex-row pb-2 gap-x-36">
                <div className="md:w-1/3 flex flex-wrap content-between text-base">
                  <h2 className="mb-6 md:mb-12">
                    <span className="bg-theme-underline">Research</span>
                  </h2>
                </div>
                <div className="mt-12 md:mt-0 md:w-2/3">
                  <p className="text-lg">
                    We’re a data-driven organisation. Explore our latest
                    research into disinformation and its harms.
                  </p>
                </div>
              </div>
              <div className="row">
                <BlogRoll blogPosts={researchPublications} />
              </div>
              <div className="row text-center pt-8">
                <LocalizedLink
                  to="/research"
                  className="button inline-block px-12 py-3 rounded-full bg-primary text-white hover:bg-primaryHover focus:bg-primaryPressed"
                >
                  See more
                </LocalizedLink>
              </div>
            </div>
          </Section>
        )}
        {!!countryStudies?.length && (
          <Section overlaySide="left" colorScheme="black">
            <div className="container py-12">
              <div className="row flex flex-col md:flex-row pb-2 gap-x-36">
                <div className="md:w-1/3 flex flex-wrap content-between text-base">
                  <h2 className="mb-6 md:mb-12">
                    <span className="bg-theme-underline">
                      Media Market Assessments
                    </span>
                  </h2>
                </div>
                <div className="mt-12 md:mt-0 md:w-2/3">
                  <p className="text-lg">
                    GDI assesses disinformation risk in media markets around the
                    world. Explore our latest country reports here.
                  </p>
                </div>
              </div>
              <div className="row">
                <BlogRoll blogPosts={countryStudies} />
              </div>
              <div className="row text-center pt-8">
                <LocalizedLink
                  to="/research"
                  className="button inline-block px-12 py-3 rounded-full bg-primary text-white hover:bg-primaryHover focus:bg-primaryPressed"
                >
                  See more
                </LocalizedLink>
              </div>
            </div>
          </Section>
        )}
        {!!blogPosts?.length && (
          <Section overlaySide="left" colorScheme="grey">
            <div className="container py-12">
              <div className="row flex flex-col md:flex-row pb-2 gap-x-36">
                <div className="md:w-1/3 flex flex-wrap content-between text-base">
                  <h2 className="mb-6 md:mb-12">
                    <span className="bg-theme-underline">Latest News</span>
                  </h2>
                </div>
                <div className="mt-12 md:mt-0 md:w-2/3">
                  <p className="text-lg">
                    Want to learn about the breaking news, trends, and thought
                    leadership within the information ecosystem? Read GDI’s
                    latest.
                  </p>
                </div>
              </div>
              <div className="row">
                <BlogRoll blogPosts={blogPosts} />
              </div>
              <div className="row text-center pt-8">
                <LocalizedLink
                  to="/blog"
                  className="button inline-block px-12 py-3 rounded-full bg-primary text-white hover:bg-primaryHover focus:bg-primaryPressed"
                >
                  See More
                </LocalizedLink>
              </div>
            </div>
          </Section>
        )}
        <Section colorScheme="white">
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

IndexTemplate.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexTemplate;

export const query = graphql`
  query IndexPage($id: String!, $locale: String!) {
    indexPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          image {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                placeholder: BLURRED
                layout: FULL_WIDTH
              )
            }
          }
          title
          hero
        }
        de {
          title
          hero
        }
      }
    }
    researchPublications: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: {
          collection: { eq: "research-publications" }
          locale: { eq: $locale }
        }
        frontmatter: { title: { ne: "" }, featured: { eq: true } }
        rawMarkdownBody: { ne: "" }
      }
      limit: 3
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
    countryStudies: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: {
          collection: { eq: "country-studies" }
          locale: { eq: $locale }
        }
        frontmatter: { title: { ne: "" }, featured: { eq: true } }
        rawMarkdownBody: { ne: "" }
      }
      limit: 3
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
    blogPosts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: {
        fields: { collection: { eq: "blog-posts" }, locale: { eq: $locale } }
        frontmatter: { title: { ne: "" }, featured: { eq: true } }
        rawMarkdownBody: { ne: "" }
      }
      limit: 3
    ) {
      edges {
        node {
          ...BlogRollItem
        }
      }
    }
  }
`;
