import * as React from 'react';
import PropTypes from 'prop-types';

import ContrastContext from '../context/contrastContext';
import { BullsIcon, CogIcon } from '../components/Icon';
import Hero from '../components/Hero';
import Section from '../components/Section';
import LocalizedLink from '../components/LocalizedLink';

function IndexPage({ heroImage, heroContent }) {
  // function sayHello() {
  // 	fetch("/.netlify/functions/hello")
  // 		.then(response => response.json())
  // 		.then(console.log)
  // };

  return (
    <>
      <Hero image={heroImage} content={heroContent} autoHeaderText={false} />
      <Section colorScheme="black">
        <ContrastContext.Consumer>
          {(context) => (
            <div className="container flex flex-col md:flex-row items-center justify-between py-12 gap-x-12">
              <div className="py-8">
                <div
                  className={`inline-block ${
                    context.isHighContrastEnabled
                      ? 'bg-primaryHC'
                      : 'bg-primary'
                  } p-3 rounded-full mb-5`}
                >
                  <BullsIcon className="w-20 h-20" />
                </div>
                <h1 className="mb-3">Our Mission</h1>
                <p className="text-2xl mb-6">
                  Our mission is to catalyse change. We aim to disrupt the
                  business model of disinformation, breaking the perverse
                  incentives that exist to create and disseminate disinformation
                  online.
                </p>
                <LocalizedLink
                  to="/mission"
                  className="button inline-block px-12 py-3 rounded-full text-2xl bg-primary text-white hover:bg-primaryHover focus:bg-primaryPressed"
                >
                  See more
                </LocalizedLink>
              </div>
              <div className="py-8">
                <div
                  className={`inline-block ${
                    context.isHighContrastEnabled
                      ? 'bg-primaryHC'
                      : 'bg-primary'
                  } p-3 rounded-full mb-5`}
                >
                  <CogIcon className="w-20 h-20" />
                </div>
                <h1 className="mb-3">What We Do</h1>
                <p className="text-2xl mb-6">
                  GDI provides independent, neutral and transparent data and
                  intelligence to advise policymakers and business leaders about
                  how to combat disinformation and its creators.
                </p>
                <LocalizedLink
                  to="/product"
                  className="button inline-block px-12 py-3 rounded-full text-2xl bg-primary text-white hover:bg-primaryHover focus:bg-primaryPressed"
                >
                  See more
                </LocalizedLink>
              </div>
            </div>
          )}
        </ContrastContext.Consumer>
      </Section>
    </>
  );
}

IndexPage.propTypes = {
  heroImage: PropTypes.object.isRequired,
  heroContent: PropTypes.string.isRequired,
};

export default IndexPage;
